import React, {useEffect, useContext} from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import {GlobalStateContext} from '../context/GlobalContextProvider'
import LayoutMaster from "../components/layout"
import '../styles/global.css'
import section from '../styles/sections.module.css'
import  '../styles/form.css'
import flex from '../styles/flexbox.module.css';
import {Section} from '../components/sections'

function QRCode({data, location}){
  const siteTitle = data.site.siteMetadata.title
  const state = useContext(GlobalStateContext)
  const {qrCode} = state.language
    useEffect(()=>{
      const isMobile = {
        Android: () => navigator.userAgent.match(/Android/i),
        BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
        iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
        Opera: () => navigator.userAgent.match(/Opera Mini/i),
        Windows: () => navigator.userAgent.match(/IEMobile/i),
        any: () => (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
      };
    
      if(isMobile.iOS()) document.location.href = "https://apps.apple.com/br/app/qzela/id1462800175"
      else if(isMobile.Android()) document.location.href = "https://play.google.com/store/apps/details?id=com.westarstg.qzela"
      else document.location.href = "https://qzela.com.br/"
    }, [])
    
    return(
        <LayoutMaster location={location} title={siteTitle}>
          <SEO title="QrCode" />
            <Section className={section.light} flex={`${flex.container} ${flex.justifyCenter} ${flex.alignCenter} min-full-height-contact`}>
              <h3>{qrCode.title}</h3>
            </Section>
        </LayoutMaster>
    )
}

export default QRCode

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`