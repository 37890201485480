import React from "react"
import section from '../styles/sections.module.css'

const Section = ({className, children, flex, id, contentClass}) =>{
    return(
        <>
            <div className="anchor">
                <span id={id}></span>
            </div>
            <section key={id} id={id} className={className}>
                <div className={`${section.wrapper} ${contentClass}`}>
                    <section className={`${flex} ${contentClass}`}>
                        {children}
                    </section>
                </div>
            </section>
        </>
    )
}
const SmallSection = ({className, children, flex, id}) =>{
    return(
        <section key={id} id={id} className={className}>
            <div className={section.smallWrapper}>
                <section className={flex}>
                    {children}
                </section>
            </div>
        </section>
    )
}

export {
    Section,
    SmallSection
}